import { GET_TASKS_URL, REDUCE_EVENT_URL, ADD_EVENT_URL, GET_DEFAULT_PROJECT_URL, TASK_UPDATE_TIME_URL, TASK_REDUCE_TIME_URL } from "../../constant"
import { GET_TASKS, TASK_UPDATE_TIME, GET_DEFAULT_PROJECTS_SUCCESS, EVENTS_TIME_ADD } from "../types"
import axios from "axios"
import AuthContext from "../../services/Auth"
export const getTasks = (id, date) => {
    return dispatch => {
        axios.get(GET_TASKS_URL + "&userId='" + id + "'&date='" + date + "'")
            .then(response => {
                const tasks = response.data;
                // Remove duplicate tasks based on a unique property, such as `Id`
                const uniqueTasks = tasks.filter((task, index, self) =>
                    index === self.findIndex(t => t.id === task.id)
                );
                return dispatch({
                    type: GET_TASKS,
                    payload: uniqueTasks
                });
            })
            .catch(error => {
                console.error("Error fetching tasks:", error);
            });
    }
}




export const addTaskTime = (index, projectAssignId, dateString, id, projectId , timeValue) => {
    return dispatch => {
        axios.post(TASK_UPDATE_TIME_URL + "&projectAssignId=" + projectAssignId + "&date=" + dateString + "&id=" + id + "&projectId=" + projectId + "&value=" + timeValue)
            .then((data) => {
                var dataVarible = data.data;
                var time = dataVarible.time;
                var id = dataVarible.id;
                if (time == null) {
                    time = 24
                }
                return dispatch({
                    type: TASK_UPDATE_TIME,
                    payload: { time: time, index: { index: index, id: id, projectId: projectId } }
                })
            }).catch(function (error) {
            });
    }
}


export const reduceTaskTime = (index, projectAssignId, dateString, id, projectId , timeValue) => {
    return dispatch => {
        axios.post(TASK_REDUCE_TIME_URL + "&projectAssignId=" + projectAssignId + "&date=" + dateString + "&id=" + id + "&projectId=" + projectId + "&value=" + timeValue)
            .then((response) => {
                var data = response.data;
                var time = data.time;
                if (time == null) {
                    time = 0
                }
                return dispatch({
                    type: TASK_UPDATE_TIME,
                    payload: { time: time, index: { index: index, id: id, projectId: projectId } }
                })
            }).catch(function (error) {
            });
    }
}

export const getDefaultEventsList = (id, date) => {
    return dispatch => {
        AuthContext.acquireToken("https://graph.microsoft.com", function (error, token) {///me/photo/$value
            axios.get("https://graph.microsoft.com/v1.0/me?$select=onPremisesExtensionAttributes",
                {
                    headers: {
                        "Authorization": "Bearer " + token,
                    }
                })
                .then((details) => {
                    axios.get(GET_DEFAULT_PROJECT_URL + "&id='" + id + "'&date='" + date + `'&department=${details?.data?.onPremisesExtensionAttributes?.extensionAttribute5 ? details?.data?.onPremisesExtensionAttributes?.extensionAttribute5.replace(/&/g, '%26'):details?.data?.onPremisesExtensionAttributes?.extensionAttribute5}`)
                        .then((response) => {
                            var defaultTask = response.data;
                             // Remove duplicate NTB Tasks based on a unique property, such as `Id`
                            const uniqueTasks = defaultTask.filter((task, index, self) =>
                                index === self.findIndex(t => t.projectId === task.projectId)
                            );
                            return dispatch({
                                type: GET_DEFAULT_PROJECTS_SUCCESS,
                                payload: uniqueTasks
                            })
                        })
                })
        })
    }
}
/**
 * to add event value increase
 * @param {*} userEmail
 * @param {*} userId
 * @param {*} userName
 * @param {*} id
 * @param {*} defaultProjectId
 * @param {*} date
 * @param {*} value
 */
export const addEventsTime = (userEmail, userId, userName, id, defaultProjectId, date, value, index) => {
    return dispatch => {
        axios.post(ADD_EVENT_URL + "&userEmail='" + userEmail + "'&userId='" + userId + "'&userName='" + userName + "'&value=" + value + "&date='" + date + "'&defaultProjectId=" + defaultProjectId + "&id=" + id + "")
            .then((response) => {
                var data = response.data;
                return dispatch({
                    type: EVENTS_TIME_ADD,
                    payload: { data: data, index: index }
                })
            })
    }
}

/**
 *
 * @param {*} id
 * @param {*} value
 * @param {*} index
 */
export const reduceEventTime = (id, value, index) => {
    return dispatch => {
        axios.post(REDUCE_EVENT_URL + "&value=" + value + "&id=" + id + "")
            .then((response) => {
                var updateEventData = response.data;
                return dispatch({
                    type: EVENTS_TIME_ADD,
                    payload: { data: updateEventData, index: index }
                })
            })
    }
}


