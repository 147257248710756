import { Modal} from 'antd';
import React, { Component } from 'react';
import { PieChart, Pie } from 'recharts';
import {TASK_CARD_STATUS} from '../../constant';


class Card extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        const RADIAN = Math.PI / 180;
        const error = function() {
            Modal.warning({
              title: 'Warning',
              content: "Today's time limit has been exceeded."
            });
          };
        const renderCustomizedLabel = ({
            cx, cy, midAngle, innerRadius, outerRadius, percent, index,
        }) => {
            const radius = innerRadius + (outerRadius - innerRadius) * -0.01;
            const x = cx + radius * Math.cos(-midAngle * RADIAN);
            const y = cy + radius * Math.sin(-midAngle * RADIAN);
            return (<>
                <text x={x - 35} fontSize={25} y={y - 25} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central" style={{ textAlign: "start", cursor: "pointer" }}>
                    {`${parseInt(this.props.time) + "H"}`}
                </text>
                <text x={x + 8} fontSize={12} y={y - 20} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central" style={{ textAlign: "center", cursor: "pointer" }}>
                    {`${(this.props.time % 1) * 60 + "min"}`}
                </text>
                <text x={x - 20} y={y + 5} fill={(this.props.project.expectedHours >= this.props.project.currentHours ? '#6fec91' : 'orange')} textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central" style={{ textAlign: "center", cursor: "pointer" }}>
                    {`${(this.props.project.currentHours * 100 / this.props.project.expectedHours).toFixed(1)}%`}
                </text>
                <text x={x - 20} y={y + 30} fill={(this.props.userTime >= this.props.time ? "#6e93ec" : "#dc3f3f")} textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central" style={{ textAlign: "center", cursor: "pointer" }}>
                    {`${(this.props.userTime === 0 || this.props.time === 0 ? 0 : this.props.userTime === 0 ? 100 : this.props.time * 100 / this.props.userTime).toFixed(1)}%`}
                </text>
            </>
            );
        };
        return (
            <div className="task_card">
                <div
                    style={(this.props.project.image != "" ? { backgroundImage: `url(${this.props.project.image})` } : { backgroundColor: "#232323" })}
                >
                    <div className="task_card_touch" style={{ backgroundImage: `linear-gradient(to bottom,#0c0c0cc2, #3c3c3e87)` }}>
                        <h6 className="task_card_title">{this.props.projectName.split(' | ')[0]}</h6>
                        {this.props &&
                            <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignContent: "center", alignItems: "center" }}>
                                <PieChart width={200} height={230} cx={90} cy={125} onClick={() => {
                                    if (this.props.totalTime <= 23) {
                                        this.props.addTaskTime(this.props.index, this.props.project.userProjectAssignId, this.props.dateString, this.props.project.userProjectId, this.props.project.id, 1)
                                    } else {
                                      error();

                                    }
                                }} >
                                    <defs>
                                        <filter id="innershadow" x0="-30%" y0="-30%" width="200%" height="200%">
                                            <feGaussianBlur in="SourceAlpha" stdDeviation="4" result="blur"></feGaussianBlur>
                                            <feOffset dy="2" dx="3"></feOffset>
                                            <feComposite in2="SourceAlpha" operator="arithmetic" k2="-1" k3="1" result="shadowDiff"></feComposite>

                                            <feFlood floodColor="#121212" floodOpacity="0.75"></feFlood>
                                            <feComposite in2="shadowDiff" operator="in"></feComposite>
                                            <feComposite in2="SourceGraphic" operator="over" result="firstfilter"></feComposite>
                                            <feGaussianBlur in="firstfilter" stdDeviation="3" result="blur2"></feGaussianBlur>
                                            <feOffset dy="-2" dx="-3"></feOffset>
                                            <feComposite in2="firstfilter" operator="arithmetic" k2="-1" k3="1" result="shadowDiff"></feComposite>

                                            <feFlood floodColor="#121212" floodOpacity="0.75"></feFlood>
                                            <feComposite in2="shadowDiff" operator="in"></feComposite>
                                            <feComposite in2="firstfilter" operator="over"></feComposite>
                                        </filter>
                                        <radialGradient id="button">
                                            <stop offset="7%" stopColor="#676767" />
                                            <stop offset="92%" stopColor="#343434" />
                                        </radialGradient>

                                    </defs>

                                    <Pie data={[{ name: "", value: (this.props.currentHours / this.props.expectedHours) * 100, fill: (this.props.currentHours >= this.props.expectedHours ? 'orange' : '#6fec91'), strokeWidth: "1px", stroke: "#343434", filter: "url(#innershadow)" },
                                    {
                                        name: "", value: (this.props.expectedHours >= this.props.currentHours ? 100 - ((this.props.currentHours / this.props.expectedHours) * 100) : 0),
                                        fill: (this.props.expectedHours >= this.props.currentHours ? '#565656' : 'orange'), strokeWidth: "1px", stroke: "#343434", filter: "url(#innershadow"
                                    }]} stroke="#232323" dataKey="value" innerRadius={102} outerRadius={109} />
                                    <Pie data={[{ name: "", value: (this.props.userTime === 0 && this.props.time === 0 ? 0 : this.props.userTime === 0 ? 100 : this.props.time * 100 / this.props.userTime), fill: (this.props.userTime >= this.props.time ? '#6e93ec' : '#dc3f3f'), strokeWidth: "1px", stroke: "#343434", filter: "url(#innershadow)" },
                                    {
                                        name: "", value: (this.props.userTime === 0 && this.props.time === 0 ? 100 : this.props.userTime === 0 ? 0 : (this.props.userTime >= this.props.time ? 100 - (this.props.time * 100 / this.props.userTime) : 0)),
                                        fill: (this.props.userTime >= this.props.time ? '#565656' : '#dc3f3f'), strokeWidth: "1px", stroke: "#343434", filter: "url(#innershadow"
                                    }]} stroke="#454545" dataKey="value" innerRadius={90} outerRadius={97} />
                                    <Pie isAnimationActive={false} children={Card} data={[{ value: 1 }]} style={{ cursor: "pointer" }}
                                        strokeWidth={0} dataKey="value" outerRadius={70} fill="url(#button)" label={renderCustomizedLabel} />
                                </PieChart>
                                {/* <div className="clickableDiv" onClick={() => {
                                    if (this.props.totalTime <= 23) {
                                        this.props.addTaskTime(this.props.index, this.props.project.userProjectAssignId, this.props.dateString, this.props.project.userProjectId, this.props.project.id)
                                    } else {
                                        message.error("Today time limit exceeded");

                                    }
                                }} style={{ width: 140, height: 140, position: "absolute", cursor: "pointer", marginTop: 0 }}>

                                </div> */}
                            </div>
                        }
                    </div>
                </div>
                <div className="task_card_touch_description">
                    <div className="task_card_touch_description_btn">
                        <div className="task_card_reduce"
                            onClick={() => this.props.reduceTaskTime(this.props.index, this.props.project.userProjectAssignId, this.props.dateString, this.props.project.userProjectId, this.props.project.id , 1)}>
                            <icon className="fa fa-minus" style={{ fontSize: 30, color: "white" }} />
                        </div>
                    </div>
                    <div className="task_card_touch_description_content">
                        {
                            this.props.project.status === TASK_CARD_STATUS &&
                            <h6 style={{ textDecoration: "underline" }}>{this.props.project.ticketName}</h6>
                        }

                        <h6 style={{ fontSize: 13 }}>{this.props.description}</h6>
                    </div>

                </div>
            </div>
        );
    }
}

export default Card;