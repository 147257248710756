import React, { Component } from 'react';
import Nav from "../../../containers/Navbar/Navbar";
import NavbarTop from "../../../containers/Navbar/NavbarTop";
import { connect } from 'react-redux';
import * as projectActions from "../../actions/projectsActions"
import ReactTable from 'react-table'
import PhotoshopPicker from "react-color"
// import 'react-table/react-table.css'
import AuthContext from '../../../services/Auth'
import { message } from 'antd';


class AddProject extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: "",
            imageURL: "",
            modalView: false,
            navSide: true,
            projectName: "",
            startDate: "",
            projectCode: "",
            endDate: "",
            currentHours: 0,
            expectedHours: "",
            message: "",
            // image: null,
            // imageType: "",
            color: "#000000",
            pickerView: false,
            // imageSize: 0,
            // imageError: "",
            error: {},
            projectNameError: "",
            messageError: "",
            startDateError: "",
            projectCodeError: "",
            endDateError: "",
            setloader: false,
            alertView: false
        }
    }
    submitForm = () => {
        let projectArray = {
            projects: []
        };
        if (!this.state.setloader) {
            this.setState({ setloader: true });
            var validationStatus = true;
            var stringRegex = new RegExp("(^[a-z A-Z0-9@_./&,$-]*)$");
            //Project Name Validation
            if (this.state.projectName.trim() == "") {
                this.setState({ projectNameError: "Enter Project Name" });
                validationStatus = false;
            } 
            // else if (!stringRegex.test(this.state.projectName)) {
            //     this.setState({ projectNameError: "Enter Valid Project Name (using a-z ,0-9 ,@ or $  )" });
            //     validationStatus = false;
            // } 
            else {
                this.setState({ projectNameError: "" });
            }

            var stringRegexProjectCode = new RegExp("(^[A-Za-z0-9._]*)$");

            if (this.state.projectCode.trim() == "") {
                this.setState({ projectCodeError: "Enter Project Code " });
            } else if (!stringRegexProjectCode.test(this.state.projectCode)) {
                this.setState({ projectCode: this.state.projectCode, projectCodeError: "Enter Valid Project Code (using a-z ,0-9 ,_  )" });
            } else {
                this.setState({ projectCodeError: "", projectCode: this.state.projectCode });
            }

            //Project Description Validation
            if (this.state.message.trim() == "") {
                this.setState({ messageError: "Enter Project Description" });
                validationStatus = false;
            } else if (!stringRegex.test(this.state.message)) {
                this.setState({ messageError: "Enter Valid Project Description (using a-z ,0-9 ,@ or $  )" });
                validationStatus = false;
            } else {
                this.setState({ messageError: "" });
            }
            var startDate = new Date(this.state.startDate);
            var endDate = new Date(this.state.endDate);

            //Start date validation
            if (this.state.startDate.trim() == "") {
                this.setState({ startDateError: "Enter Start Date" });
                validationStatus = false;
            } else {
                this.setState({ startDateError: "" });
            }

            //End Date Validation
            if (this.state.endDate.trim() == "") {
                this.setState({ endDateError: "Enter End Date" });
                validationStatus = false;
            } else if (endDate < startDate) {
                this.setState({ endDateError: "Enter Valid End Date" });
                validationStatus = false;
            } else {
                this.setState({ endDateError: "" });
            }
            //Current Hours Validation
            if (this.state.currentHours === "") {
                this.setState({ currentHoursError: "Enter Current Hours" });
                validationStatus = false;
            } else if (this.state.currentHours < 0) {
                this.setState({ currentHoursError: "Enter Valid Current Hours" });
                validationStatus = false;
            } else if (this.state.expectedHours != 0 && this.state.currentHours > this.state.expectedHours) {
                this.setState({ currentHoursError: "Enter Valid Current Hours. Current Hours cannot be greater than Expected Hours" });
                validationStatus = false;
            } else {
                this.setState({ currentHoursError: "" });
            }

            //Expected Hours Validation
            if (this.state.expectedHours == 0) {
                this.setState({ expectedHoursError: "Enter Expected Hours" });
                validationStatus = false;
            } else if (this.state.expectedHours < 0) {
                this.setState({ expectedHoursError: "Enter Valid Expected Hours" });
                validationStatus = false;
            } else if (this.state.currentHours > this.state.expectedHours) {
                this.setState({ expectedHoursError: "Enter Valid Expected Hours. Expected Hours cannot be less than Current Hours" });
                validationStatus = false;
            } else {
                this.setState({ expectedHoursError: "" });
            }

            //Expected Hours Validation
            // if (this.state.image == "") {
            //     this.setState({ imageError: "Upload Project Image" });
            //     validationStatus = false;
            // } else {
            //     this.setState({ imageError: "" });
            // }

            for (var validationItem in this.state.error) {
                if (this.state.error[validationItem] == false) {
                    validationStatus = false;
                }
            }

            if (validationStatus) {
                message.loading("Submit in progress..")
                var value = {
                    projectName: this.state.projectName,
                    startTime: this.state.startDate,
                    endDate: this.state.endDate,
                    currentHours: this.state.currentHours,
                    expectedHours: this.state.expectedHours,
                    message: this.state.message,
                    //image:this.state.image,
                    color: this.state.color.substring(1) + "a1",
                    status: "1",
                   // image: this.state.image,
                   // imageName: this.state.imageType,
                    //imageSize: this.state.imageSize,
                    projectCode: this.state.projectCode,
                    createdBy: AuthContext.getCachedUser() && AuthContext.getCachedUser().profile.unique_name
                }
                projectArray.projects.push(value);
                this.props.addProjects(projectArray);
                this.setState({ alertView: true })
            } else {
                this.setState({ setloader: false })
            }
        }
    }

    static getDerivedStateFromProps = (props, state) => {
        if (props.projects.addProject && state.alertView && state.setloader) {
            message.success("Successfully add project");
            //document.getElementById('imageUpload').value = "";
            return {
                projectName: "",
                projectCode: "",
                startDate: "",
                endDate: "",
                currentHours: 0,
                expectedHours: 0,
                message: "",
                color: "#000000",
                // image: "",
                // imageType: "",
                // imageSize: 0,
                setloader: false

            }
        } else if (props.projects.addProject == false) {
            return {
                setloader: false
            }
        }
        return null
    }
    // imageStateChange = (event) => {
    //     const dataURL = event.currentTarget.result; // Extracting the result directly from the event
    //     if (!dataURL.startsWith('data:image/')) {
    //         console.error('The file provided is not an image.');
    //         return; 
    //     }
    //     const base64String = dataURL.split(',')[1]; // Split at the comma to get just the base64 content
    //     if (base64String && window.atob(base64String)) { // Validates if it's proper base64
    //         this.setState({ image: base64String }); // Update state with base64 string
    //     } else {
    //         console.error('Invalid base64 data.');
    //     }
    // };

    // imageUpload = (file) => {
    //     var reader = new FileReader();
    //     let imageName = file.name;
    //     reader.onloadend = this.imageStateChange;
    //     var dateCurrent = new Date();
    //     this.setState({ imageType: dateCurrent.toISOString() + imageName, imageSize: file.size });
    //     reader.readAsDataURL(file);
    // }

    componentDidMount() {
        this.props.getActiveProjects();
    }

    render() {
        return (
            <>
                {
                    this.state.pickerView &&
                    <div id="pickerDiv" className="pickerDiv" >
                        <div style={{ width: "100%", height: "100%", position: "absolute" }} onClick={() => { this.setState({ pickerView: false }) }}>
                        </div>

                        <div className="pickerDivContent">

                            <div style={{ display: "flex", justifyContent: 'flex-end', cursor: "pointer" }} onClick={() => { this.setState({ pickerView: false }) }}>
                                <div style={{ backgroundColor: "black", color: "white", borderRadius: 20, width: 25, paddingLeft: 6 }}>X</div>
                            </div>

                            <PhotoshopPicker
                                color={this.state.color}
                                id="colorPickerzviewer"
                                onChangeComplete={(value) => { this.setState({ color: value.hex }); }}
                            />
                        </div>
                    </div>
                }
                <div className={(this.state.navSide ? "content-div" : "content-div-full")}>

                    <div className="container-form">
                        <h2 style={{ fontWeight: 700 }}>Add Project</h2>
                        <div className="row">
                            <div className="col-md-6">
                                <p>Project Code<span style={{ color: "red" }}>*</span></p>
                                <input type="text" className="form-control"
                                    id={"projectCodeId"}
                                    name={"projectCode"}
                                    value={this.state.projectCode}
                                    onChange={(value) => {
                                        var stringRegex = new RegExp("(^[A-Za-z0-9._]*)$");
                                        // if (value.target.value.trim() == "") {
                                        //     this.setState({ projectCodeError: "Enter Project Code", projectCode: value.target.value });
                                        // } else
                                        if (!stringRegex.test(value.target.value)) {
                                            this.setState({ projectCode: value.target.value, projectCodeError: "Enter Valid Project Code (using a-z ,0-9  )" });
                                        } else {
                                            this.setState({ projectCodeError: "", projectCode: value.target.value });
                                        }
                                    }}
                                />
                                <span id="projectCodeIdError" style={{ color: "red" }}>{this.state.projectCodeError}</span>
                            </div>
                            <div className="col-md-6">
                                <p>Project Name<span style={{ color: "red" }}>*</span></p>
                                <input id="projectName" type="text" className="form-control"
                                    value={this.state.projectName}
                                    onChange={(value) => {
                                        var stringRegex = new RegExp("(^[a-z A-Z0-9@_./&,$-]*)$");
                                        if (value.target.value.trim() == "") {
                                            this.setState({ projectNameError: "Enter Project Name", projectName: value.target.value });
                                        } else if (!stringRegex.test(value.target.value)) {
                                            this.setState({ projectName: value.target.value, projectNameError: "Enter Valid Project Name (using a-z ,0-9 ,@ / $ / , or & )" });
                                        } else {
                                            this.setState({ projectNameError: "", projectName: value.target.value });
                                        }
                                    }}
                                />
                                <span style={{ color: "red" }}>{this.state.projectNameError}</span>
                            </div>
                            <div className="col-md-8">
                                <p>Project Description<span style={{ color: "red" }}>*</span></p>
                                <textarea type="text" className="form-control"
                                    value={this.state.message}
                                    id="projectDescription"
                                    onChange={(value) => {
                                        var stringRegex = new RegExp("(^[a-z A-Z0-9@_./&,$-]*)$");
                                        if (value.target.value.trim() == "") {
                                            this.setState({ message: value.target.value, messageError: "Enter Project Description" });
                                        } else if (!stringRegex.test(value.target.value)) {
                                            this.setState({ message: value.target.value, messageError: "Enter Valid Project Description (using a-z ,0-9 ,@ / $ / , or & )" });
                                        } else {
                                            this.setState({ messageError: "", message: value.target.value });
                                        }
                                    }}
                                />
                                <span style={{ color: "red" }}>{this.state.messageError}</span>
                            </div>
                            <div className="col-md-6">
                                <p>Start Date (mm/dd/yyyy)<span style={{ color: "red" }}>*</span></p>
                                <input type="date"
                                    className="form-control"
                                    id="startDate"
                                    value={this.state.startDate}
                                    onChange={(value) => {
                                        //Start date validation
                                        if (value.target.value.trim() == "") {
                                            this.setState({ startDateError: "Enter Start Date" });
                                        } else if (this.state.endDate != "" && new Date(this.state.endDate) < new Date(value.target.value)) {
                                            this.setState({ endDateError: "Enter Valid End Date" });

                                        } else {
                                            this.setState({ startDateError: "", startDate: value.target.value });
                                        }

                                    }}
                                />
                                <span style={{ color: "red" }}>{this.state.startDateError}</span>
                            </div>
                            <div className="col-md-6">
                                <p>End Date (mm/dd/yyyy)<span style={{ color: "red" }}>*</span></p>
                                <input type="date" className="form-control"
                                    value={this.state.endDate}
                                    id="endDate"
                                    onChange={(value) => {
                                        if (value.target.value.trim() == "") {
                                            this.setState({ endDateError: "Enter End Date" });
                                        } else if (new Date(value.target.value) < new Date(this.state.startDate)) {
                                            this.setState({ endDateError: "Enter Valid End Date" });
                                        } else {
                                            this.setState({ endDateError: "", endDate: value.target.value });
                                        }

                                    }}
                                />
                                <span style={{ color: "red" }}>{this.state.endDateError}</span>
                            </div>

                            <div className="col-md-6">
                                <p>Current Hours<span style={{ color: "red" }}>*</span></p>

                                <input type="number" className="form-control"
                                    value={this.state.currentHours}
                                    id="currentHours"
                                    onChange={(value) => {
                                        var valueEnterd = parseInt(value.target.value);
                                        //Current Hours Validation
                                        if (value.target.value == "") {
                                            this.setState({ currentHours: valueEnterd, currentHoursError: "Enter Current Hours" });
                                        } else if (valueEnterd < 0) {
                                            this.setState({ currentHours: valueEnterd, currentHoursError: "Enter Valid Current Hours" });
                                        } else if (this.state.expectedHours != 0 && valueEnterd > this.state.expectedHours) {
                                            this.setState({ currentHours: valueEnterd, currentHoursError: "Enter Valid Current Hours. Current Hours cannot be greater than Expected Hours" });
                                        } else {
                                            this.setState({ currentHoursError: "", currentHours: valueEnterd });
                                        }
                                    }}

                                />
                                <span style={{ color: "red" }}>{this.state.currentHoursError}</span>
                            </div>

                            <div className="col-md-6">
                                <p>Expected Hours<span style={{ color: "red" }}>*</span></p>

                                <input type="number" className="form-control"
                                    value={this.state.expectedHours}
                                    id="expectedHours"
                                    onChange={(value) => {
                                        var valueEnterd = parseInt(value.target.value);
                                        if (valueEnterd == 0) {
                                            this.setState({ expectedHours: valueEnterd, expectedHoursError: "Enter Expected Hours" });
                                        } else if (valueEnterd < 0) {
                                            this.setState({ expectedHours: valueEnterd, expectedHoursError: "Enter Valid Expected Hours" });
                                        } else if (this.state.currentHours > valueEnterd) {
                                            this.setState({ expectedHours: valueEnterd, expectedHoursError: "Enter Valid Expected Hours. Expected Hours cannot be less than Current Hours" });
                                        } else {
                                            this.setState({ expectedHoursError: "", expectedHours: valueEnterd });
                                        }
                                    }}

                                />
                                <span style={{ color: "red" }}>{this.state.expectedHoursError}</span>
                            </div>

                            {/* <div className="col-md-6">
                                <p>Image<span style={{ color: "red" }}>*</span></p>
                                <input alt="Submit" accept="image/png, image/jpeg, image/jpg" type="file" id="imageUpload" className="form-control" onChange={(e) => {
                                    if (e.target.files[0]) {
                                        const name = e.target.files[0].name;
                                        const lastDot = name.lastIndexOf('.');
                                        const ext = name.substring(lastDot + 1);
                                        if (ext == "png" || ext == "jpeg" || ext == "jpg") {
                                            this.setState({ imageError: "" });
                                            var error = this.state.error;
                                            if (e.target.files[0] == null) {
                                                error["image"] = false;
                                                this.setState({ imageError: "Upload Project Image", error: error });
                                            } else if ((e.target.files[0].size / 1024) / 1024 > 2) {
                                                error["image"] = false;
                                                this.setState({ imageError: "Maximum Project Image Size is 2MB", error: error });
                                            } else {
                                                error["image"] = true;
                                                this.imageUpload(e.target.files[0]);
                                                this.setState({ error: error });
                                            }
                                        } else {
                                            document.getElementById('imageUpload').value = "";
                                            alert("Invalid file type")
                                        }
                                    }


                                }} /> */}
                                {/* <span style={{ color: "red" }}>{this.state.imageError}</span> */}
                            {/* </div> */}
                            <div className="col-md-6">
                                <p>Color</p>
                                <div id="colorPickerBtn" style={{ width: 100, height: 20, backgroundColor: this.state.color, cursor: "pointer", borderColor: "gray", border: 2 }}
                                    onClick={() => {
                                        (this.state.pickerView ? this.setState({ pickerView: false }) :
                                            this.setState({ pickerView: true }))
                                    }}>
                                </div>
                            </div>
                        </div>
                        <div style={{ display: "flex", justifyContent: 'flex-end' }}>
                            {
                                (
                                    this.state.setloader ?
                                        <div className="btn-submit" ><icon className="fa fa-spinner fa-spin" style={{ color: "white" }} /></div>
                                        :
                                        <button className="btn-submit" id="submitBtn" onClick={() => { this.submitForm() }}>Submit</button>
                                )
                            }
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        projects: state.projectReducer
    }
}



export default connect(mapStateToProps, projectActions)(AddProject);